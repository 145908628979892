.wrapper {
  max-width: 429px;
  width: 95%;

  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  z-index: 5;
  right: 0px;
  top: 70px;
  padding: 24px 0px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12),
    0px 8px 20px 0px rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  background: var(--White-Surface);
  height: calc(100vh - 120px);
}
.container {
  padding: 0px 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;

  /* Shadow sm */
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 16px;
}
.chekout {
  display: flex;
  align-items: center;
  gap: 12px;
}
.title {
  color: var(--Primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 116.667%;
  letter-spacing: -1px;
}
.numberOfItems {
  color: var(--bg);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%;
  letter-spacing: -0.2px;
  border-radius: 100%;
  background: var(--Primary);
  padding: 16px;
  width: 32px;
  height: 32px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.close {
  font-size: 20px;
  padding: 12px;
  color: var(--Primary);
}
.items {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 100%;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
}
.item {
  display: flex;

  align-items: center;
  gap: 12px;
  border-radius: 16px;
  padding: 12px 16px;
  padding-left: 10px;
  width: 100%;

  position: relative;
  cursor: pointer;
}
.img {
  max-width: 50px;
  width: 100%;
}
.itemInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}
.selected {
  background: var(--Surface-soft);
}
.price {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}
.price span {
  font-weight: 400;
  color: var(--Secondary);
}
.deleteItem {
  color: var(--bg);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 142.857%;
  letter-spacing: -0.2px;
  border-radius: 100%;
  background: var(--Primary);

  width: 32px;
  height: 32px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;
  z-index: 1;
}
.deleteIcon {
  color: var(--bg);
}
.bitCoin {
  background: var(--Blue-Surface);
  padding: 12px 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: auto;
}
.bitCoinLogo {
  max-width: 40px;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.key {
  color: var(--Primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  letter-spacing: -0.2px;
}
.name {
  color: var(--Secondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
}
.priceInfo {
  padding: 6px;
  display: flex;
  flex-direction: column;
  background: var(--Surface-soft);
  border-radius: 16px;
}

.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}
.fee {
  align-items: center;
}
.total {
  border-top: 1px dashed var(--Border);
}
.label {
  color: var(--Primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  letter-spacing: -0.2px;
}

.amount {
  color: var(--Primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  letter-spacing: -0.2px;
  display: flex;
  gap: 5px;
}
.amount span {
  color: var(--Secondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}
.fee .amount {
  line-height: 142.857%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 520px) {
  .wrapper {
    max-width: 110%;
    margin: 0 auto;
    width: 110%;
    left: 50%;
    transform: translateX(-50%);
    top: 60px;
  }
  .title {
    font-size: 22px;
  }

  .itemInfo {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
  .price {
    margin: 0;
  }
}
@media only screen and (max-width: 399px) {
  .wrapper {
    padding: 20px 0px;
  }
  .header {
    padding-left: 6px;
    padding-right: 6px;
  }
  .container {
    padding: 0px 10px;
  }
  .itemName {
    font-size: 14px;
  }
  .price {
    font-size: 14px;
  }
  .list {
    padding: 12px;
  }
}
