.wrapper {
  padding: 80px 0;
  background: linear-gradient(
    96deg,
    rgba(31, 113, 235, 0.1) 1.53%,
    rgba(225, 229, 19, 0.06) 50.32%,
    rgba(20, 220, 88, 0.1) 99.12%
  );
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  border-radius: 24px;
  background: var(--Background-CTA, rgba(0, 0, 0, 0.4));

  /* Shadow md */
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  max-width: 1280px;
  width: 90%;
  margin: 0 auto;
  padding: 64px;
}
.infoContainer {
  max-width: 560px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.apeContainer {
  max-width: 467px;

  width: 100%;
}
.img {
  width: 100%;
}
.button {
  margin-top: 24px;
}
.tagline {
  font-size: 18px;
}
@media only screen and (max-width: 1199px) {
  .container {
    padding: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .title {
    font-size: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 650px;
  }
  .title,
  .tagline {
    text-align: center;
  }
  .infoContainer {
    align-items: center;
  }
  .button {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 60px 0;
  }
  .tagline {
    font-size: 16px;
  }
  .title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    padding: 24px 18px;
  }
}
