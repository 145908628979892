.wrapper {
  display: flex;

  width: 100%;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  border: 0.7px solid var(--Border);
  background: var(--bg);

  display: flex;
  flex-direction: column;
  gap: 0px;
}
.tabContainer {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 10px 10px;

  width: 100%;
  border-radius: 12px;
}
.activeTab {
  background: var(--Surface-soft);
}
.tabName {
  color: var(--Primary);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  letter-spacing: -0.2px;
}
.button {
  padding: 10px;
}
.shop {
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    display: none;
  }
}
