.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 5px;
  align-items: start;
}
.header {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  border: 0.5px solid var(--Border);
  border-bottom: 0;
  border-radius: 12px 12px 0 0;
}
.price {
  margin-left: auto;
  font-weight: 700;
}
.nft {
  display: flex;
  flex-direction: column;
  border-radius: 0px;

  border-bottom: 0;
  background: var(--bg);
}
.nft:hover {
  transform: translate(0);
  opacity: 1;
}
.imgContainer {
  width: 100%;
  height: 100%;

  position: relative;

  overflow: hidden;
  border: 1px solid var(--Border);

  border-radius: 0 0 12px 12px;
}

.descriptionContainer {
  transform: translateY(100%);
  position: absolute;
  width: 100%;
  padding: 15px;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.3s ease-in;
  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow-y: auto;
  border-radius: 0 0 12px 12px;
  min-height: 100%;
}

.detailsBox {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.nft:hover .descriptionContainer {
  transform: translate(0);
  transition: 0.3s ease-in;
}
.details {
  color: #e8e8e8;
  font-size: 14px;
}
.img {
  display: block;
  object-fit: cover;
  width: 100%;
  border-radius: 0 0 12px 12px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 640px) {
  .wrapper {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }
}

.descriptionContainer {
  scrollbar-width: thin;
  scrollbar-color: #e8e8e8 rgba(250, 250, 250, 0.2);
}

/* Chrome, Edge, and Safari browsers */
.descriptionContainer::-webkit-scrollbar {
  width: 1px;
  border-radius: 5px;
}
.descriptionContainer::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.descriptionContainer::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
