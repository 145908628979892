.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 0;
  max-width: 1280px;
  width: 95%;
  margin: 0 auto;
}

/* .wrapper {
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  gap: 12px;
} */
.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
}

.filter,
.trading {
  display: flex;
  padding: 16px 18px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: var(--Surface-soft);
  cursor: pointer;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: var(--Surface-medium);
  cursor: pointer;
}
.icon {
  color: var(--Surface-hard);
}
.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 4px;

  gap: 1px;

  border-radius: 16px;
  background: var(--Surface-soft);
}
.tab {
  width: 100%;
  text-align: center;
  color: var(--Secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: -0.2px;
  display: flex;
  justify-content: center;
  padding: 12px 12px;
  align-items: center;
  gap: 10px;

  border-radius: 12px;
  cursor: pointer;
}
.activeTab {
  color: var(--Primary);
  font-weight: 500;
  background: var(--Tabs);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12),
    0px 8px 20px 0px rgba(0, 0, 0, 0.02);
}
.inputContainer {
  display: flex;
  padding: 8px 12px 8px 16px;
  justify-content: space-between;
  align-items: center;

  border-radius: 12px;
  background: var(--Surface-soft);

  width: 100%;
}
.input {
  color: var(--Secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: -0.2px;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
}
.input::placeholder {
  color: var(--Secondary);
}
.input:-moz-placeholder {
  color: var(--Secondary);
}
.searchButton {
  padding: 8px 10px;
}
.layoutContainer {
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 1px;
  border-radius: 16px;
  background: var(--Surface-soft);
}
.layout {
  display: flex;
  width: 48px;
  height: 48px;

  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  cursor: pointer;
}
.activeLayout {
  background: var(--Tabs);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
}
.layoutIcon {
  max-width: 20px;
  width: 100%;
}
.button {
  display: none;
}
.projectsWrapper {
  display: grid;
  grid-template-columns: 296px 1fr;
  align-items: flex-start;
  gap: 32px;
}
@media only screen and (max-width: 1199px) {
  /* .wrapper {
    grid-template-columns: 1fr auto auto auto;
  } */
  /* .tabs {
    order: 1;
    grid-column: 1/-1;
  } */
  .inputContainer {
    order: 2;
  }
  .filter {
    order: 3;
  }
  .trading {
    order: 4;
  }
  .layoutContainer {
    order: 5;
  }
}
@media only screen and (max-width: 991px) {
  /* .wrapper {
    grid-template-columns: 1fr 1fr auto auto;
  } */
  .layoutContainer {
    display: none;
  }
  .projectsWrapper {
    grid-template-columns: 1fr;

    gap: 20px;
  }
}
@media only screen and (max-width: 767px) {
  /* .wrapper {
    grid-template-columns: 1fr auto auto;
    gap: 12px 8px;
  } */

  .button {
    display: flex;
    order: 3;
    padding: 8px 10px;
  }
  .shop {
    font-size: 24px;
  }
  .filter,
  .trading {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .container {
    max-width: 500px;
    margin: 0 auto;
    width: 90%;
  }
  .wrapper {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
