.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--bg);
  width: 100%;
  cursor: pointer;
}
.activeItem {
  background: var(--Surface-soft);
}
.label {
  color: var(--Secondary);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: -0.2px;
}
.icon {
  max-width: 24px;
  width: 100%;
}
